import React, { useState } from 'react';

import { RementoPage } from '@/modules/routing';
import { useServices } from '@/Services';
import { useSignOut } from '@/services/api/auth/auth.service.hook';

function DebugDeleteAccount() {
  const { userService } = useServices();
  const signOut = useSignOut();

  const [running, setRunning] = useState(false);
  const [ran, setRan] = useState(false);
  const [error, setError] = useState<null | string | unknown>(null);

  if (!running) {
    return (
      <div>
        <button
          onClick={async () => {
            try {
              const confirmed = confirm('This action cannot be undone. Are you sure?');

              if (confirmed) {
                setRunning(true);

                try {
                  await userService.deleteUser();
                } catch (err) {
                  setError(err instanceof Error ? err.message : err);
                }

                await signOut();
                setRan(true);
                setTimeout(() => {
                  window.location.href = window.location.origin;
                }, 5000);
              }
            } catch (err) {
              setRan(true);
              setError(err instanceof Error ? err.message : err);
            }
          }}
          style={{ border: '1px black solid', padding: 10, borderRadius: 10, margin: 10, backgroundColor: '#CCCCCC' }}
        >
          Delete signed in user
        </button>
      </div>
    );
  }
  if (!ran) {
    return <p>Deleting, it will take a second...</p>;
  }
  if (error != null) {
    return <p>Error - {String(error)}</p>;
  }
  return <p>Done! Redirecting in a few seconds...</p>;
}

export function DebugDeleteAccountPage() {
  return (
    <RementoPage type="empty">
      <DebugDeleteAccount />
    </RementoPage>
  );
}
